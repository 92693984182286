<template>
  <div v-if="authenticating" class="loadscreen">
    <LoadingSpinner/>
  </div>
  <div v-else-if="account !== null">
    <slot />
  </div>
  <div
    v-else
    class="d-flex justify-content-center align-items-center"
    style="padding: 30px"
  >
    <div style="max-width: 530px; width: 100%;">
      <div class="card">
        <div class="card-body">
          <form
            @submit.prevent="login"
            class="d-flex flex-column gap-2 auth-form"
          >
            <h4 class="auth-form__title">Требуется аутентификация</h4>
            <input
              type="text"
              required
              v-model="credentials.username"
              class="auth-form__field"
              placeholder="Логин"
            >
            <input
              type="password"
              required
              v-model="credentials.password"
              class="auth-form__field"
              placeholder="Пароль"
            >
            <button
              type="submit"
              class="auth-form__btn"
            >
              Войти
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'AuthenticationRequired',

  components: {
    LoadingSpinner,
  },

  data: function () {
    return {
      authenticating: true,

      credentials: {
        'username': null,
        'password': null
      }
    }
  },
  computed: {
    account: function () {
      return this.$store.state.account
    }
  },
  methods: {
    login: function () {
      this.$store.dispatch('login', this.credentials)
    }
  },
  mounted: function () {
    if (window.localStorage.getItem('token')) {
      this.$store.dispatch('authenticate').then(() => {
        this.authenticating = false
      })
    } else {
      this.authenticating = false
    }
  }
}
</script>

<style lang="scss">
.loadscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #1E272E;
}

.card {
  border: none !important;
}

.card-body {
  background-color: #222B32;
}

.auth-form {
  &__title {
    color: rgba(255, 255, 255, 0.9);
  }

  &__field {
    width: 100%;
    transition: margin-bottom 0.4s ease-in-out;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
    background-color: #222B32;
    padding: 15px;
    border: 1px solid #414C55;
    border-radius: 6px;
    outline: none;
  }

  &__btn {
    min-height: 50px;
    width: 100%;
    margin-top: 30px;
    padding: 15px;
    border: none;
    border-radius: 6px;
    background: #402DA0;
    color: rgba(255, 255, 255, 0.9);
    font-size: 15px;
    line-height: 18px;
        &:disabled {
        background: rgba(47, 56, 63, 0.5);
        color: rgba(255, 255, 255, 0.2);
      }
      &:hover {
        background: #4b35bd;
        color: rgba(255, 255, 255, 0.9);
      }
  }
}
</style>
