class ApiEndpoint {
  constructor (endpoint) {
    this.url = `${process.env.VUE_APP_API_ORIGIN}/api/${endpoint}`
  }

  async get(objectId=null, searchParams=null) {
    let url = this.url
    if (objectId !== null) {
      url += `${objectId}/`
    }
    if (searchParams !== null) {
      url += `?${new URLSearchParams(searchParams)}`
    }
    return fetch(url, {
      headers: {
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    }).then(response => response.json())
  }

  async post (data) {
    return fetch(this.url, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }

  async put (objectId, data) {
    let url = `${this.url}${objectId}/`
    return fetch(url, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }

  async delete (objectId) {
    let url = `${this.url}${objectId}/`
    return fetch(url, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }
}

class UploadFileApiEndpoint {
  constructor () {
    this.url = `${process.env.VUE_APP_API_ORIGIN}/api/add_attachment/`
  }

  async upload (file) {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(this.url, {
      method: 'post',
      body: formData,
      headers: {
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            return response.text().then(text => {
              throw new Error(text)
            })
          }
        })
  }
}

class AttachmentsApiEndpoint extends ApiEndpoint {
  async post(files) {
    const formData = new FormData()

    for (const file of files) {
      formData.append('file', file)
    }

    return fetch(this.url, {
      method: 'post',
      body: formData,
      headers: {
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return response.text().then(text => {
            throw new Error(text)
          })
        }
      })
  }
}

class TasksApiEndpoint extends ApiEndpoint {
  async archive (objectId) {
    let url = `${this.url}${objectId}/archive/`
    return fetch(url, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }

  async unarchive (objectId) {
    let url = `${this.url}${objectId}/unarchive/`
    return fetch(url, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }

  async paid (taskIds) {
    let url = `${this.url}paid/`
    return fetch(url, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      },
      body: JSON.stringify({ 'task_ids': taskIds })
    })
  }

  async unpaid (taskIds) {
    let url = `${this.url}unpaid/`
    return fetch(url, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      },
      body: JSON.stringify({ 'task_ids': taskIds })
    })
  }

  async move (objectId, columnId, order) {
    let url = `${this.url}${objectId}/move/`
    return fetch(url, {
      method: 'put',
      body: JSON.stringify({ 'column_id': columnId, 'order': order }),
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }

  async documents (objectId) {
    let url = `${this.url}${objectId}/documents/`
    return fetch(url, {
      headers: {
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    }).then(response => response.json())
  }

  async attachments (objectId) {
    let url = `${this.url}${objectId}/attachments/`
    return fetch(url, {
      headers: {
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    }).then(response => response.json())
  }
}

class TaskCheckListsApiEndpoint extends ApiEndpoint {
  reorder(data) {
    let url = `${this.url}reorder/`

    return fetch(url, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }
}

class TaskCheckListItemsApiEndpoint extends ApiEndpoint {
  reorder(data) {
    let url = `${this.url}reorder/`

    return fetch(url, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }
}

class EmployeeAccountApiEndpoint extends ApiEndpoint {
  async createReport(employeeId, taskIds, month, year) {
    let url = `${this.url}${employeeId}/create_report/`
    return fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      },
      body: JSON.stringify({ 'task_ids': taskIds, 'month': month, 'year': year })
    }).then(response => response.json())
  }

  async get_current () {
    let url = `${this.url}get_current/`
    return fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    }).then(response => response.json())
  }
}

class ProjectsApiEndpoint extends ApiEndpoint {
  async createReport (projectId, taskIds, month, year) {
    let url = `${this.url}${projectId}/create_report/`
    return fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      },
      body: JSON.stringify({ 'task_ids': taskIds, 'month': month, 'year': year })
    }).then(response => response.json())
  }

  async addtoreport (projectId, taskIds, reportId) {
    let url = `${this.url}${projectId}/addtoreport/`
    return fetch(url, {
      method: 'put',
      body: JSON.stringify({ 'report_id': reportId, 'task_ids': taskIds }),
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    })
  }

  async getAttachments (projectId) {
    let url = `${this.url}${projectId}/attachments/`
    return fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      }
    }).then(response => response.json())
  }

  async createDefaultTasks (objectId, columnId) {
    let url = `${this.url}${objectId}/create_default_tasks/`
    return fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      },
      body: JSON.parse({
        column_id: columnId
      })
    })
  }

  async deleteFile (projectId, fileId) {
    let url = `${this.url}${projectId}/remove_attachment`
    return fetch(url, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      },
      body: JSON.stringify({'attachment_id': fileId})
    })
  }
  async deleteTag (projectId, tagId) {
    let url = `${this.url}${projectId}/remove_tag`
    return fetch(url, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Token ${window.localStorage.getItem('token')}`
      },
      body: JSON.stringify({'tag_id': tagId})
    })
  }
}

export const ClientAccountsApi = new ApiEndpoint('client_accounts/')

export const EmployeeAccountsApi = new EmployeeAccountApiEndpoint('accounts/')

export const EmployeeReportsApi = new ApiEndpoint('employee_reports/')

export const BoardsApi = new ApiEndpoint('boards/')

export const ColumnsApi = new ApiEndpoint('columns/')

export const UploadFileApi = new UploadFileApiEndpoint()

export const TagsApi = new ApiEndpoint('tags/')

export const TasksApi = new TasksApiEndpoint('tasks/')

export const TaskCommentsApi = new ApiEndpoint('task_comments/')

export const TaskHistoryApi = new ApiEndpoint('task_history/')

export const TaskChecklistsApi = new TaskCheckListsApiEndpoint('task_checklists/')

export const TaskChecklistItemsApi = new TaskCheckListItemsApiEndpoint('task_checklist_items/')

export const TicketsApi = new ApiEndpoint('tickets/')

export const ProjectCommentsApi = new ApiEndpoint('project_comments/')

export const ProjectLanguagesApi = new ApiEndpoint('project_languages/')

export const ProjectStatusesApi = new ApiEndpoint('project_statuses/')

export const ProjectStagesApi = new ApiEndpoint('project_stages/')

export const ProjectToDosApi = new ApiEndpoint('project_todos/')

export const ProjectReportsApi = new ApiEndpoint('project_reports/')

export const ProjectsApi = new ProjectsApiEndpoint('projects/')

export const AttachmentsApi = new AttachmentsApiEndpoint('attachments/')
