<template>
  <div :class="{ active: active }" class="account-menu">
    <div class="account-menu__header">
      <div class="account-preview account-menu__preview">
        <div class="account-rating account-rating--sm">
          <div class="account-rating__content">
            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.8682 3.94825L6.38871 3.58789L5.28031 1.34082C5.25003 1.2793 5.20023 1.2295 5.13871 1.19922C4.98441 1.12305 4.79691 1.18653 4.71976 1.34082L3.61136 3.58789L1.13187 3.94825C1.06351 3.95801 1.00101 3.99024 0.953159 4.03907C0.895309 4.09853 0.863431 4.17852 0.864529 4.26147C0.865628 4.34442 0.899614 4.42354 0.959018 4.48145L2.75296 6.23047L2.32914 8.7002C2.3192 8.75765 2.32555 8.81674 2.34749 8.87076C2.36942 8.92478 2.40605 8.97158 2.45323 9.00584C2.5004 9.0401 2.55623 9.06046 2.61439 9.06461C2.67255 9.06876 2.7307 9.05652 2.78226 9.0293L5.00003 7.86329L7.21781 9.0293C7.27835 9.06153 7.34867 9.07227 7.41605 9.06055C7.58597 9.03125 7.70023 8.87012 7.67093 8.7002L7.2471 6.23047L9.04105 4.48145C9.08988 4.4336 9.1221 4.3711 9.13187 4.30274C9.15824 4.13184 9.0391 3.97364 8.8682 3.94825Z" fill="#FCB310"/>
            </svg>

            <div class="account-rating__points">
              9999
            </div>

            <div class="account-rating__status">
              Профи
            </div>
          </div>
        </div>

        <div v-if="account.user.first_name" class="account-preview__name">
          {{ account.user.first_name }} {{ account.user.last_name }}
        </div>

        <div class="account-preview__bottom">
          <div class="account-warns">
            <ul class="account-warns__list">
              <li class="account-warns__item">
                <svg class="account-warns__icon" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="4" height="5" rx="1" fill="#FFED00"/>
                </svg>
              </li>
              <li class="account-warns__item">
                <svg class="account-warns__icon" width="2" height="5" viewBox="0 0 2 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="1" height="4" rx="0.5" stroke="#89949C" stroke-opacity="0.5"/>
                </svg>
              </li>
              <li class="account-warns__item">
                <svg class="account-warns__icon" width="2" height="5" viewBox="0 0 2 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="1" height="4" rx="0.5" stroke="#89949C" stroke-opacity="0.5"/>
                </svg>
              </li>
              <li class="account-warns__item">
                <svg class="account-warns__icon" width="2" height="5" viewBox="0 0 2 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="1" height="4" rx="0.5" stroke="#89949C" stroke-opacity="0.5"/>
                </svg>
              </li>
            </ul>
          </div>

          <div class="account-preview__position">
            {{ account.position.name }}
          </div>
        </div>
      </div>

      <button @click="active = !active" class="account-btn" type="button">
        <img class="account-btn__photo" :src="accountPhotoUrl" alt="" width="40" height="40">

        <i class="account-btn__icon ico ico-arrow-down"></i>
      </button>
    </div>

    <div class="account-dropdown account-menu__dropdown">
      <div class="account-dropdown__header">
        <div class="account-dropdown__role">
          Администратор
        </div>
      </div>

      <div class="account-dropdown__body">
        <div class="account-rating account-rating--md account-dropdown__rating">
          <div class="account-rating__content">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.1891 5.51714L10.2219 4.94058L8.44847 1.34527C8.40003 1.24683 8.32034 1.16714 8.2219 1.11871C7.97503 0.996832 7.67503 1.09839 7.55159 1.34527L5.77815 4.94058L1.81097 5.51714C1.70159 5.53277 1.60159 5.58433 1.52503 5.66246C1.43247 5.75759 1.38146 5.88558 1.38322 6.0183C1.38498 6.15103 1.43936 6.27762 1.5344 6.37027L4.40472 9.16871L3.72659 13.1203C3.71069 13.2122 3.72086 13.3067 3.75595 13.3932C3.79105 13.4796 3.84966 13.5545 3.92514 13.6093C4.00062 13.6641 4.08995 13.6967 4.183 13.7033C4.27605 13.71 4.3691 13.6904 4.45159 13.6468L8.00003 11.7812L11.5485 13.6468C11.6453 13.6984 11.7578 13.7156 11.8657 13.6968C12.1375 13.65 12.3203 13.3921 12.2735 13.1203L11.5953 9.16871L14.4657 6.37027C14.5438 6.29371 14.5953 6.19371 14.611 6.08433C14.6532 5.81089 14.4625 5.55777 14.1891 5.51714V5.51714Z" fill="#FCB310"/>
            </svg>

            <div class="account-rating__points">
              9999
            </div>

            <div class="account-rating__status">
              Профи
            </div>
          </div>

          <div class="account-rating__msg">
            До следующего уровня осталось 2766 баллов
          </div>
        </div>

        <div class="account-warns">
          <ul class="account-warns__list">
            <li class="account-warns__item">
              <svg class="account-warns__icon" width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="8" height="10" rx="1" fill="#FFED00"/>
              </svg>
            </li>
            <li class="account-warns__item">
              <svg class="account-warns__icon" width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="3" height="5" rx="0.5" stroke="#89949C" stroke-opacity="0.5"/>
              </svg>
            </li>
            <li class="account-warns__item">
              <svg class="account-warns__icon" width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="3" height="5" rx="0.5" stroke="#89949C" stroke-opacity="0.5"/>
              </svg>
            </li>
            <li class="account-warns__item">
              <svg class="account-warns__icon" width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="3" height="5" rx="0.5" stroke="#89949C" stroke-opacity="0.5"/>
              </svg>
            </li>
          </ul>

          <div class="account-warns__msg">
            Карточки сгорают через 2 месяца 25 дней
          </div>
        </div>
      </div>

      <div class="account-dropdown__actions">
        <router-link to="/" class="account-dropdown__action">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.15 12.95L5 11.47L5.85 12.95L7.15 12.2L6.3 10.72H8V9.22H6.3L7.15 7.75L5.85 7L5 8.47L4.15 7L2.85 7.75L3.7 9.22H2V10.72H3.7L2.85 12.2L4.15 12.95ZM9.85 12.2L11.15 12.95L12 11.47L12.85 12.95L14.15 12.2L13.3 10.72H15V9.22H13.3L14.15 7.75L12.85 7L12 8.47L11.15 7L9.85 7.75L10.7 9.22H9V10.72H10.7L9.85 12.2ZM22 9.22H20.3L21.15 7.75L19.85 7L19 8.47L18.15 7L16.85 7.75L17.7 9.22H16V10.72H17.7L16.85 12.2L18.15 12.95L19 11.47L19.85 12.95L21.15 12.2L20.3 10.72H22V9.22Z" fill="#89949C"/>
            <path d="M20 17H4C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17Z" fill="#89949C"/>
          </svg>

          <span>
            Сменить пароль
          </span>
        </router-link>

        <div class="account-dropdown__action" @click="logout">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 7H13C13.5523 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 13.5523 17 13 17H5V7Z" fill="#89949C"/>
            <path d="M16.8201 7.68341C16.4944 7.41202 16 7.6436 16 8.06752V11H10C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H16V15.9325C16 16.3564 16.4944 16.588 16.8201 16.3166L22 12L16.8201 7.68341Z" fill="#89949C"/>
          </svg>

          <span>
            Выход
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountMenu',

  computed: {
    account() {
      return this.$store.state.account
    },

    accountPhotoUrl() {
      if (!this.account.photo) {
        return require('../assets/images/placeholder.jpg')
      }

      return `${process.env.VUE_APP_API_ORIGIN}${this.account.photo.url}`
    },
  },

  data() {
    return {
      active: false,
    }
  },

  methods: {
    onMouseUp(e) {
      if (!e.target.closest('.account-menu')) {
        this.active = false
      }
    },
    logout: function() {
      window.localStorage.clear('token')
      document.location.href = '/'
    }
  },

  mounted() {
    document.addEventListener('mouseup', this.onMouseUp);
  },
}
</script>

<style lang="scss">
.account-menu {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
  }

  &__preview {
    margin-right: 10px;
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 10px);
    right: -20px;
    z-index: 15;

    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: transform 0.1s, opacity 0.1s,
                visibility 0.1s;
  }

  &.active &__dropdown {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.account-btn {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;

  background: none;

  &__photo {
    width: 40px;
    height: 40px;

    border-radius: 50%;
    box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }

  &__icon {
    margin-left: 10px;

    @media (max-width: 1300px) {
      display: none;
    }
  }
}

.account-preview {
  text-align: right;

  @media (max-width: 1300px) {
    display: none;
  }

  &__name {
    color: rgba(255, 255, 255, 0.5);

    font-size: 15px;
    line-height: 15px;
  }

  &__position {
    margin-left: 4px;

    font-size: 13px;
    line-height: 14px;

    color: rgba(255, 255, 255, 0.3);
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .account-warns {
    margin-bottom: -4px;
  }
}

.account-rating {
  &--sm {
    font-size: 9px;
    line-height: 11px;
  }

  &--md {
    font-size: 12px;
    line-height: 14px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__points {
    margin-left: 4px;

    color: rgba(255, 255, 255, 0.7);

    letter-spacing: 0.01em;
  }

  &__status {
    margin-left: 5px;

    color: #FCB310;

    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  &__msg {
    margin-top: 3px;

    color: rgba(255, 255, 255, 0.3);

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
  }
}

.account-warns {
  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 1px;
    }
  }

  &__icon {
    display: block;
  }

  &__msg {
    margin-top: 4px;

    color: rgba(255, 255, 255, 0.3);

    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
  }
}

.account-dropdown {
  border: 1px solid #414C55;
  box-shadow: 0px 15px 30px rgb(0 0 0 / 50%);
  border-bottom-left-radius: 10px;
  overflow: hidden;
  background-color: #2B343B;

  text-align: right;

  &__body {
    padding: 10px 15px;

    background-color: #1E272E;
  }

  &__role {
    padding: 10px 16px;

    color: rgba(255, 255, 255, 0.5);

    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
  }

  &__rating {
    margin-bottom: 14px;
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #2D3A44;
    padding: 8px 16px;

    background-color: transparent;
    transition: background-color 0.1s;

    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    line-height: 16px;
    cursor: pointer;

    span {
      margin-top: -1px;
      margin-left: 6px;

      color: #89949C;
    }

    &:hover {
      background-color: #1E272E;
    }
  }
}
</style>
