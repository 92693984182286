<template>
  <div class="board-card" :style="`--board-color: ${board.color};`">
    <router-link :to="{ name: 'board', params: { boardSlug: board.slug } }" class="board-card__link">
      <div class="board-card__body">
        <div class="board-users board-card__users">
          <ul class="board-users__list">
            <li v-for="employee in employees.slice(0, maxEmployeesToShow)" :key="employee.id" class="board-user board-users__item">
              <img class="board-user__photo" :src="getEmployeePhotoUrl(employee)" alt="">
            </li>
          </ul>

          <div v-if="employeesLeft > 0" class="board-users__rest">
            + {{ employeesLeft }}
          </div>
        </div>

        <div class="board-columns">
          <ul class="board-columns__list">
            <li v-for="column in columns" :key="column.id" class="board-column board-columns__item" :style="`--board-column-color: ${column.color ?? 'currentColor'};`">
              <i class="board-column__icon" :style="`--board-column-icon: url(${getColumnIconUrl(column)})`"></i>

              <div class="board-column__name">
                {{ column.name }}
              </div>

              <div class="board-column__task-count">
                {{ column.total_tasks }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="board-card__footer">
        <BoardName :board="board"/>
      </div>
    </router-link>
  </div>
</template>

<script>
import BoardName from '@/components/BoardName.vue'

import {
  EmployeeAccountsApi,
  ColumnsApi
} from '@/api'

export default {
  name: 'BoardCard',
  props: {
    board: {
      type: Object,
      required: true,
    },
  },

  computed: {
    employeesLeft() {
      return this.employees.length - this.maxEmployeesToShow
    },
  },

  components: {
    BoardName,
  },

  data() {
    return {
      maxEmployeesToShow: 5,
      employees: [],
      columns: [],
    }
  },

  methods: {
    getEmployeePhotoUrl(employee) {
      if (!employee.photo) {
        return require('../assets/images/placeholder.jpg')
      }

      return `${process.env.VUE_APP_API_ORIGIN}${employee.photo.url}`
    },

    getColumnIconUrl(column) {
      if (!column.icon) {
        return 'default icon'
      }

      return `${process.env.VUE_APP_API_ORIGIN}${column.icon.url}`
    },
  },

  mounted() {
    EmployeeAccountsApi.get(null, { board: this.board.slug })
      .then(employees => this.employees = employees)

    ColumnsApi.get(null, { board: this.board.slug, is_visible_on_board_card: 1 })
      .then(columns => this.columns = columns)
  },
}
</script>

<style lang="scss">
.board-card {
  width: 320px;
  min-height: 256px;

  border-radius: 10px;
  overflow: hidden;
  background-color: #2B343B;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.97);
  }

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__body {
    flex-grow: 1;
    padding: 20px 28px 16px 20px;
    border: 2px solid transparent;
    border-bottom: none;
    transition: border-color 0.2s;

    border-radius: 10px 10px 0 0;
  }

  &:hover &__body {
    border-color: var(--board-color);
  }

  &__users {
    margin-bottom: 12px;
  }
}

.board-users {
  display: flex;
  align-items: center;
  height: 40px;

  &__list {
    display: flex;
    margin: 0;
    padding: 0 4px;
  }

  &__rest {
    color: #fff;
  }

  &__item {
    &:not(:first-child) {
      margin-left: -16px;
    }
  }
}

.board-user {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #FFF;

  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
  box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.2);

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.board-columns {
  &__list {
    margin: 0;
    padding: 0;
  }
}

.board-column {
  display: flex;
  align-items: center;
  padding: 6px 0;

  &__icon {
    width: 24px;
    height: 24px;

    mask-image: var(--board-column-icon);
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: var(--board-column-color);
  }

  &__name,
  &__task-count {
    color: var(--board-column-color);

    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
  }

  &__name {
    margin-left: 3px;
  }

  &__task-count {
    margin-left: auto;
  }
}
</style>
