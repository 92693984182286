import { createStore } from 'vuex'

export default createStore({
  state: {
    account: null,
  },
  getters: {
  },
  mutations: {
    setEmployeeAccount: function (store, account) {
      store.account = account
    }
  },
  actions: {
    authenticate: function (context) {
      return fetch(`${process.env.VUE_APP_API_ORIGIN}/api/accounts/authenticate/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${window.localStorage.getItem('token')}`
        }
      }).then(response => {
        if (response.ok) {
          return response.json()
        }
        return null
      }).then(account => {
        context.commit('setEmployeeAccount', account)
      })
    },
    login: function (context, credentials) {
      fetch(`${process.env.VUE_APP_API_ORIGIN}/api-auth/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      }).then(response => {
        return response.json()
      }).then(data => {
        window.localStorage.setItem('token', data.token)
        context.dispatch('authenticate')
      })
    }
  },
  modules: {
  }
})
